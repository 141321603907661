@import 'variables.scss';
@import 'mixins.scss';

@keyframes rotate {
    0% {rotate: 0deg;}
    30% {rotate: 8deg;}
    70% {rotate: -8deg;}
    100% {rotate: 0deg;}
}

.Header {
    @include display(row, space-between);
    padding: 15px 30px;
    line-height: 45px;
    font-family: $primary-font;
    position: fixed;
    top: 0;
    width: 100vw;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    letter-spacing: 2px;
    z-index: 99;

    @media (max-width: 1200px) {
        letter-spacing: 1px;
    }

    @media (max-width: 786px) {
        height: 90px;
    }
}

.Header .mobileIcon {
    display: none;

    @media (max-width: 786px) {
        display: flex;
        color: $primary-color;
        font-size: $large-font-size;
        margin-right: 22px;
    }
}

.Header ul {
    list-style: none;
    @include display(row, space-around);

    @media (max-width: 786px) {
        @include display(column, center);
        border: 2px solid $third-background;
        position: absolute;
        top: 90px;
        opacity: 0;
        padding: 20px 0;
        border-bottom-left-radius: 15px;
        width: 180px;

        .mode {
            margin-top: 15px;
        }
    }
}

.Header.lightMode ul {
    @media (max-width: 786px) {
        background-color: $primary-background;
    }
}

.Header.darkMode ul {
    @media (max-width: 786px) {
        background-color: $secondary-background;
    }
}

.Header ul.open {
    opacity: 1;
    right: 0;
}

.Header li {
    font-size: $base-font-size;
    padding: 0 8px;

    @media (max-width: 1200px) {
        font-size: $base-font-size;
      }
    
    @media (max-width: 1000px) {
        font-size: $small-font-size;
        padding: 0 6px;
    }

    @media (max-width: 786px) {
        font-size: $mobile-font-size;
        padding: 0;
    }
}

.Header li a {
    padding: 10px 10px;
    text-decoration: none;
    color: $primary-color;
    border-radius: 20px;
    transition: 200ms all ease-in-out;

    @media (max-width: 1200px) {
        padding: 10px 8px;
    }
} 

.Header.lightMode li a:hover {
    color: rgba($primary-color, 0.8);
    background: linear-gradient(to top, $secondary-background 0%, transparent 20%, transparent 80%, $secondary-background 100%);

    @media (max-width: 786px) {
        color: rgba($primary-color, 0.8);
        background: linear-gradient(to top, $third-background 0%, transparent 20%, transparent 80%, $third-background 100%);
    }
}

.Header.darkMode li a:hover {
    color: rgba($primary-color, 0.8);
    background: linear-gradient(to top, $third-background 0%, transparent 20%, transparent 80%, $third-background 100%);

    @media (max-width: 786px) {
        color: rgba($primary-color, 0.8);
        background: linear-gradient(to top, $primary-color 0%, $secondary-background 20%, $secondary-background 80%, $primary-color 100%);
    }
}

.Header.lightMode li a.active {
    color: black;

    @media (max-width: 786px) {
        color: $third-background;
    }
}

.Header.darkMode li a.active {
    color: $third-background;

    @media (max-width: 786px) {
        color: $third-background;
    }
}

.Header.darkMode li a.active:hover {
    color: $primary-color;
}

.Header li .home {
    text-shadow: none;
    font-size: $base-line-height;
}

.Header li button {
    max-width: 100px;
}

.Header.darkMode li .home {
    color: $third-background;
}

.Header.darkMode li .home:hover {
    color: $primary-color;
    background: none;
}

.Header.lightMode li .home:hover {
    background: none;
    color: $primary-color;
}

.Header .material-symbols-outlined {
    font-size: $large-font-size;
}

.Header .logoContainer {
    width: 280px;
    @include display(row, flex-start);

    @media (max-width: 1200px) {
        width: 180px;
    }

    @media (max-width: 1000px) {
        width: 160px;
    }

    @media (max-width: 786px) {
        width: 80px;
    }
}

.Header .logo {
    width: math.div(320px, 4);
    height: math.div(216px, 4);
    animation: rotate 20s linear;
    border: none;
}

.Header.lightMode {
    background: linear-gradient(112.1deg, transparent, $third-background 18.2%, $third-background 80.2%, transparent 98%);
    color: $primary-color;
}

.Header.darkMode {
    background: linear-gradient(112.1deg, transparent, $secondary-color 20.2%, $secondary-color 70.2%, transparent 90%);
    color: $primary-color;

    button {
        span {
            color: $primary-color;
        }
    }
}

.mobileIcon {
    cursor: pointer;
    transition: all 200ms ease;
}

.mobileIcon:hover {
    color: $third-background;
}

.categories {
    position: absolute;
    top: 78%;
    right: 0;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    list-style: none;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    z-index: 10;
}

.Header.darkMode .categories {
    background-color: $secondary-background;
}

.Header.lightMode .categories {
    background-color: $third-background;
}

.Header .basket {
    font-size: $large-font-size
}

.Header .basketCount {
    color: $primary-color;
    background-color: red;
    padding:  4px 10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}