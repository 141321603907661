.Poems {
    padding-top: 40px;
}

.Poems.darkMode {
    background: $secondary-background;
    color: $primary-color;
}

.Poems.lightMode {
    background: $primary-color;
    color: $secondary-color;
}

.Poems .poemContainer {
    width: 500px;
    margin: $base-margin auto;
    border: $base-border;
    padding: 5px;

    @media (max-width: 786px) {
        width: 65%;
    }

    @media (max-width: 480px) {
        width: 90%;
    }
}

.Poems h1 {
    padding-top: 100px;
}

.Poems h2 {
    margin: 0 auto;
    margin-top: 20px;
}

.Poems h3 {
    font-style: italic;
    margin: $small-margin;
}

.Poems h4 {
    margin-bottom: 20px auto;
    max-width: 800px;
    color: $third-background;
    font-weight: bold;

    @media (max-width: 1000px) {
        max-width: 750px;
    }

    @media (max-width: 786px) {
        max-width: 90%;
    }
}

.Poems .authorContainer {
    padding: 20px;
    margin: 10px;
    @include display(row, flex-end);
    gap: 6px;

    .featherAuthor{
        border: none;
        width: 20px;
        height: 20px;
    }

    h5 {
        font-size: $mobile-font-size;
        font-style: italic;
    }
}

.Poems .donateContainer {
    @include display(row, center);
    width: 100vw;
    gap: 40px;
}

.Poems .decription {
    margin: $base-margin auto;
    padding-top: 40px;
    text-align: justify;
    max-width: 80%;
}

.Poems p {
    margin: 0 auto;
    text-align: center;
    width: 100%;
}

.Poems .MainPoster {
    max-width: 200px;
    height: auto;
    margin: $small-margin;

    @media (max-width: 786px) {
        max-width: 150px;
    }
}

.poemWrapper {
    position: relative;
    overflow: hidden;
}

.topHalf, .bottomHalf {
    position: absolute;
    width: 250px;
    height: 90%;
    top: 5%;
    z-index: 1;
    background-image: url('../media/upcomingExhibition/container.png');
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 480px) {
        width: 180px;
    }
}

.topHalf {
    right: 50%;
}

.bottomHalf {
    left: 50%;
}