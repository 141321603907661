.BasketPage {
    background-color: $third-background;
    height: 100vh;
    @include display(row, space-between);
}

.Basket  {
    padding: $mobile-margin;
    width: 600px;
    margin: 0 auto;

    @media (max-width: 768px) {
        width: 100vw;
    }

    .totalPrice {
        margin-top: 20px;

        p {
            margin-top: 20px;
            text-align: center;
        }
        
    }
}

.Basket.lightMode  {
    background-color: $primary-color;
}

.Basket.darkMode  {
    background-color: $secondary-background;
    color: $primary-color;
}

.Basket .basketItem {
    border: $base-border;
    padding: $small-padding;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    @include display(row, space-between);

    @media (max-width: 768px) {
        width: 90%;
    }

    h3 {
        font-size: $base-font-size;
        width: 70%;

        @media (max-width: 768px) {
            font-size: $small-font-size;
            font-weight: 500;
        }
    }

    .imgContainer {
        width: 100px;
        height: 100px;
        position: relative;
        text-align: center;
        margin: 0 auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border: none;
        }
    }

    p {
        text-align: center;
        margin: 0 auto;
    }
}

.Basket p {
    text-align: center;
    margin-bottom: 20px;
    width: 90%;
}

.Basket input{
    padding: 15px;
    border-radius: 15px;
    border: $base-border;
    margin-bottom: 20px;
    width: 80%;
    font-family: $third-font;
    font-size: $base-font-size;
}

.Basket textarea {
    padding: 15px;
    border-radius: 15px;
    border: $base-border;
    margin-bottom: 20px;
    height: 120px;
    width: 80%;
    font-family: $third-font;
    font-size: $base-font-size;
}

.Basket .buttonTotal {
    width: 120px;
    transition: all 200ms ease-in-out;
}

.Basket .buttonTotal:hover {
    background-color: $third-background;
}
