.ArtistSection {
  img {
    width: 25%;

    @media (max-width: 768px) {
      width: 40%;
    }

    @media (max-width: 480px) {
      width: 50%;
    }
  }

  p {
    margin: $base-margin auto;
    @media (max-width: 768px) {
      width: 80%;
    }

  }

  .prevButton,
  .nextButton {
    background-color: transparent;
    box-shadow: none;
    max-width: 60px;
  }

  .material-symbols-outlined {
    color: $third-background;
  }

  .Events {
    margin: $base-margin auto;
    width: 60%;

    @media (max-width: 1200px) {
      width: 80%;
    }

    @media (max-width: 480px) {
      width: 90%;
    }
  }

  .eventsContainer {
    color: $third-background;
    font-weight: 600;
    font-size: $base-font-size;
    line-height: $base-line-height;

    @media (max-width: 480px) {
      width: 90%;
      margin: auto;
      font-size: $small-font-size;
    }
  }

  .hiddenEventDescrition {
    margin: $base-margin;
    font-size: $base-font-size;
    line-height: $base-line-height;
    text-align: justify;

    @media (max-width: 786px) {
      font-size: $small-font-size;
      line-height: $base-line-height;
    }
  }

  .portfolio {
    border: none;
    padding: $small-padding-button;
    border-radius: $base-radius;
    background-color: $third-background;
    color: $primary-color;
    transition: 200ms all ease-in-out;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    position: relative;
    margin: $base-margin auto;
    display: block;
    width: 60%;
    font-size: $small-font-size;

    @media (max-width: 480px) {
      padding: $small-padding-button;
    }
  }

  .portfolio:hover {
    background-color: $secondary-background;
    color: $third-background;
    box-shadow: none;
    border: 1px solid $third-background;
  }

  .ArtStudioGallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .main-slide {
      position: relative;
      width: 100%;
      max-width: 500px;
      max-height: 500px;
      overflow: hidden;
    }
    
    .main-image {
      width: 100%;
      transition: opacity 0.5s ease-in-out;
    }
    
    .prevButton, .nextButton {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      font-size: 24px;
      cursor: pointer;
      z-index: 1;
    }
    
    .prevButton {
      left: 10px;
    }
    
    .nextButton {
      right: 10px;
    }
    
    .slide {
      display: none;
    }
    
    .slide.active {
      display: block;
      animation: fadeIn 1s ease-in-out;
    }
    
    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    
    .thumbnails {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    
    .thumbnail {
      width: 60px;
      height: 60px;
      margin: 0 5px;
      cursor: pointer;
      border: 2px solid transparent;
      transition: border 0.3s ease;

      @media (max-width: 786px) {
        height: 40px;
        width: 40px;
        margin: 0 1px;
      }
    }
    
    .thumbnail.active {
      border: 2px solid #000;
    }
    
    .dots {
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    
    .dot {
      height: 15px;
      width: 15px;
      margin: 0 5px;
      background-color: #bbb;
      border-radius: 50%;
      display: inline-block;
      cursor: pointer;
      transition: background-color 0.3s ease;

      @media (max-width: 786px) {
        height: 10px;
        width: 10px;
        margin: 0 3px;
      }
    }
    
    .dot.active {
      background-color: $third-background;
    }
  }
}

#artistTop {
  scroll-margin-top: 8rem;
}
