.Artwork {
    padding: 30px;
    width: 100%;
    height: 100%;

    .imageContainer {
      position: relative;
   }
    
    .mainImage {
      width: 100%;
      max-width: 600px;
      height: auto;
      cursor: pointer;
      transition: transform 0.3s ease;
    }
    
    .zoomable:hover {
      transform: scale(1.2) translateY(30px);
    }
    
    .smallImage {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      margin-top: 10px;
      width: 150px;
      border: 2px solid #ccc;
      transition: all 0.3s ease;
    }
    
    .smallImage:hover {
      border: 1px solid $third-background;
      transform: scale(2.8) translateY(-30px);
    }

  h2 {
    font-size: $medium-font-size;
    line-height: $medium-line-height;
    font-weight: bold;

    @media (max-width: 786px) {
      font-size: $base-font-size;
      line-height: $base-line-height;
    }

    @media (max-width: 480px) {
      font-size: $small-font-size;
      line-height: $small-line-height;
    }
  }

  h3 {
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 400;
    opacity: 0.9;

    @media (max-width: 786px) {
      font-size: $small-font-size;
      line-height: $small-line-height;
    }

    @media (max-width: 480px) {
      font-size: $mobile-font-size;
      line-height: $mobile-line-height;
    }
  }

  h4 {
    font-weight: 800;
  }

  p {
    opacity: 0.9;

    @media (max-width: 786px) {
      font-size: $small-font-size;
      width: 80%;

    }

    @media (max-width: 480px) {
      font-size: $mobile-font-size;
      line-height: $small-line-height;
    }
  }

  .close {
    color: $third-background;
    font-size: 25px;
    font-weight: bold;
    position: absolute;
    top: 2%;
    left: 96%;
    z-index: 99;
    cursor: pointer;

    @media (max-width: 786px) {
      left: 95%;
      font-size: 25px;
    }

    @media (max-width: 480px) {
      left: 85%;
      font-size: 20px;
    }
  }

  .close:hover {
    color: $secondary-background;
  }

  .buttons {
    background-color: black;
    padding: 10px;
  }

  button {
    @media (max-width: 786px) {
      max-width: 100px;
    }
  }

  .buy {
    margin: 0 10px 10px 0;
  }

  .payPal {
    margin-left: 15px;
    background-color: rgb(34, 34, 139);
  }
}

.socialMediaShare {
  padding: 40px;
  background-color: black;
  @include display(row, center);
  gap: 30px;
  padding: 10px;

  span {
    @include display(row, center);
    gap: 5px;
    transition: 200ms all ease-in-out;
  }

  span:hover {
    color: $primary-color;
    font-weight: 600;
  }

  .shareTitle {
    margin-bottom: 20px;
    font-weight: bold;
    position: relative;
    top: 3px;
  }

  .shareTitle:hover {
    color: $third-background;
  }

  @media (max-width: 860px) {
    @include display(column, center);
    gap: 0;

    span:hover {
      transform: translateX(25px);
    }

    .shareTitle:hover {
      transform: translateX(0);
    }
   
  }
}

.Artwork.lightMode {
  color: black;
  background-color: $primary-color;
}

.Artwork.darkMode {
  color: $primary-color;
  background-color: black;
}

