.ExhibitionSection.lightMode .exhibitionHeader {
  background: linear-gradient(180deg, $primary-color 0%, $primary-color 34%,#753d08 35%, #753d08 79%, $primary-color 80%,#fdfeff 100%);
  color: $secondary-color;
}

.ExhibitionSection.darkMode .exhibitionHeader {
  background: linear-gradient(180deg, $primary-background 0%, $primary-background 34%,$secondary-background 35%, $secondary-background 79%, $primary-background 80%,$primary-background 100%);
  color: $primary-color;
}

.ExhibitionSection .exhibitionHeader {
  @include display(row, space-around);
  list-style: none;
  margin: 81px 0 0 0;
  padding-top: 20px;

  @media (max-width: 786px) {
    padding-top: 5px;
  }

  li {
    transition: 600ms all ease-in-out;
    position: relative;
    padding: 0 20px 20px;

    @media (max-width: 786px) {
      padding: $mobile-padding;
    }
  }

  li h3 {
    font-size: $base-font-size;
    padding: 0;
    margin: 0;

    @media (max-width: 786px) {
      font-size: $small-font-size
    }

    @media (max-width: 480px) {
      font-size: $mobile-font-size
    }
  }

  li img {
    width: 100px;
    height: auto;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);

    @media (max-width: 786px) {
      width: 60px;
    }

    @media (max-width: 480px) {
      width: 50px;
    }
  }

  li:hover {
    background-color: $secondary-background;
    color: $third-background;
    box-shadow: none;
    transform: translateY(-5px);
  }
}

.ExhibitionSection h1 {
  margin-top: 0;
}

.ExhibitionSection ul {
  margin-top: 120px;
}

#exhibitionTop {
  scroll-margin-top: 8rem;
}


