.ShopContainer{

    @include display(column, center);

    h2 {
        font-size: $base-font-size;
        line-height: $base-line-height;
        font-family: $secondary-font;
    }

    .Shop {
        @include display(row, center);
        gap: 10px;

        .specialDealImage {
            max-width: 150px;
            margin-bottom: 10px;
    
            @media (max-width: 786px) {
                width: 250px;
                margin-bottom: 40px;
            }
        
            @media (max-width: 480px) {
                width: 200px;
            }
        }

        h2 span {
            color: black;
            font-weight: 400;
        }
}

    .buttonContainer {
        @include display(row, center);
        gap: 10px;
        width: 400px;
        margin: 10px auto;
        margin-top: 40px;
    }

    .PayPal {
        background-color: rgb(19, 19, 122);
    }
}