.CurrentExhibition {
  border-top: $large-border;
  padding: $large-margin;
  text-align: center;

  @media (max-width: 768px) {
    width: 100%;
    border-top: $small-border;
    margin: 0 auto;
  }

  @media (max-width: 480px) {
    border-top: $mobile-border;
  }

  .imageContainer {
    @include display(row, center);
    gap: 20px;
    margin-top: 100px;
  }

  img {
    width: 20%;

    @media (max-width: 768px) {
      width: 30%;
    }

    @media (max-width: 480px) {
      width: 40%;
    }
  }

  h3 {
    font-weight: 400;
    font-size: $base-font-size;
    line-height: $base-line-height;
    width: 40%;
    margin: $base-margin auto;

    @media (max-width: 1200px) {
      width: 60%;
    }

    @media (max-width: 786px) {
      font-size: $small-font-size;
      line-height: $base-line-height;
    }

    @media (max-width: 480px) {
      width: 80%;
      font-size: $mobile-font-size;
    }
  }

  h4 {
    width: 80%;
    text-align: center;
    margin: 0 auto;
  }

  p {
    width: 80%;
    padding: 20px 0 60px 0;
    @media (max-width: 480px) {
      font-size: $small-font-size;
      margin-bottom: 15px;
    }
  }

  .openingNight {
    @media (max-width: 480px) {
      font-size: $small-font-size;
      margin-bottom: 15px;
      margin-top: 15px;
      text-align: center;
    }
  }

  .partnerContainer {
    background-color: $third-background;
    color: $primary-color;
    @include display(row, center);

    a {
      width: 200px;
      padding: $base-padding;
      border-radius: $base-radius;

      @media (max-width: 480px) {
        width: 120px;
        padding: $small-padding;
      }
    }
  }
}

.CurrentExhibition.lightMode {
  background-color: $primary-color;
}


.CurrentExhibition.darkMode {
  background-color: $primary-background;
  color: $primary-color;
}