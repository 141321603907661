.AboutSection {
    p {
        @media (max-width: 768px) {
            width: 80%;
        }
    }
}

.AboutSection video {
    width: 400px;
    height: 400px;
    margin: 30px 0 70px 0;

    @media (max-width: 768px) {
        width: 300px;
        height: 300px;
    }

    @media (max-width: 480px) {
        width: 250px;
        height: 250px;
    }
}