.PastExhibition {
  padding: $large-margin;

  img {
    width: 20%;

    @media (max-width: 1200px) {
      width: 30%;
    }

    @media (max-width: 768px) {
      width: 40%;
    }
  }

  p {
    margin-top: $base-margin;
  }

  h5 {
    @media (max-width: 480px) {
      font-size: $mobile-font-size;
    }
  }

  .prevButton,
  .nextButton {
    background-color: transparent;
    box-shadow: none;
  }

  .material-symbols-outlined {
    color: $third-background;
  }

  .descriptionContainer {
    margin-top: $base-margin;
  }
}
