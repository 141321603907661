@import 'variables.scss';
@import 'mixins.scss';

@keyframes rotate {
    0% {rotate: 0deg;}
    30% {rotate: 8deg;}
    70% {rotate: -8deg;}
    100% {rotate: 0deg;}
}

.Footer {
    background-color: $secondary-background;
    color: $primary-color;
    @include display(column, center);
    padding: 40px 0 15px 0;
    line-height: 45px;
    font-family: $primary-font;
    width: 100vw;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.9);
    letter-spacing: 2px;
    font-size: $base-font-size;

    @media (max-width: 1200px) {
        padding: 10px 0;
        line-height: $large-line-height;
        letter-spacing: 1px;
    }

    @media (max-width: 768px) {
        font-size: $small-font-size;
    }
}

.Footer.lightMode {
    background-color: rgba($primary-background, 0.9);
}

.Footer.darkMode {
    background-color: $secondary-background;
}

.Footer .container {
    @include display(row, space-around);
    margin-bottom: 40px;
    height: 280px;
    width: 100vw;

    @media (max-width: 1600px) {
        margin: 20px;
    }

    @media (max-width: 1200px) {
        margin: 0;
        padding: 0;
        height: 540px;
        
    }

    @media (max-width: 768px) {
        @include display(column, center);
        height: 900px;
    }
}

.Footer .logoContainer {
    width: 280px;

    @media (max-width: 1600px) {
        width: 300px
    }

    @media (max-width: 1200px) {
        width: 180px
    }

    @media (max-width: 1000px) {
        width: 160px;
    }

    @media (max-width: 786px) {
        margin-bottom: 20px;
        width: 80px
    }   

}

.Footer .logo {
    width: math.div(320px, 4);
    height: math.div(216px, 4);
    margin: 0 auto;
    display: flex;
    animation: rotate 20s linear;
    border: none;
}

.Footer ul {
    list-style: none;
    @include display(row, center);

    ul {
        @include display(column, center);

        @media (max-width: 768px) {
           border-bottom: 2px solid $primary-color;
           padding-bottom: 20px;
        }
    }

    @media (max-width: 1120px) {
        @include display(column, center);
        margin: 20px;
    }
}

.Footer li {
    margin: 0 35px;
    @include display(row, center);
    font-size: $base-font-size;

    @media (max-width: 768px) {
        font-size: $small-font-size;
     }

}
    
.Footer li span {
    @include display(row, space-between);
    margin: 0 8px;
    font-weight: bold;
}

.Footer.lightMode li span {
    color: $primary-color;
}

.Footer .titleList {
    font-weight: 700;

    @media (max-width: 1200px) {
        font-weight: 600;
    }

}

.Footer li a {
    text-decoration: none;
    color: $primary-color;
    transition: 200ms all ease-in-out;
} 

.Footer li a:hover, a:focus {
    color: $third-background;
}

.Footer .socialMedia {
    @include display(column, center);
    position: relative;
}

.Footer .socialMedia button {
    border: none;
    background: transparent;
    color: $primary-color;
    letter-spacing: 2px;
    font-family: $primary-font;
    font-size: $base-font-size;
    cursor: pointer;
    transition: 200ms all ease-in-out;
    box-shadow: none;
    min-width: 300px;

    @media (max-width: 1200px) {
        letter-spacing: 1px;
    }

    @media (max-width: 786px) {
        font-size: $small-font-size;
    }
}

.Footer .socialMedia button:hover {
    color: $third-background;
    transform: none;
}

.Footer li .socialIcons {
    background-color: $primary-background;
    color: $primary-color;
    border-radius: 20px;
    position: absolute;
    top: 35px;
    width: 200px;
    @include display(center,space-around)
}

.Footer li div a {
    padding: 10px 15px 0 15px;
    font-size: 20px;

    @media (max-width: 1200px) {
        font-size: 16px;
        padding: 8px 12px 0 12px;
    }

    @media (max-width: 786px) {
        font-size: 14px;
        padding: 6px 10px 0 10px;
    }
}

.Footer li div a:hover {
    color: $third-background;
}

.Footer .terms {
    width: 360px;
    transition: 200ms all ease-in-out;
    text-align: center;

    @media (max-width: 1600px) {
        width: 300px;
    }

    @media (max-width: 1200px) {
        width: 250px;
    }

    @media (max-width: 1000px) {
        width: 200px;
    }

    @media (max-width: 786px) {
        margin-bottom: 40px;
        font-weight: 600;
        width: 150px;
    }
}

.Footer .terms  a {
    text-decoration: none;
    color: $primary-color;
}

.Footer .terms a:hover {
    color: $third-background;
}