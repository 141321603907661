.JoinSupportSection {
  margin: $medium-margin;
  max-width: 800px;
  text-align: center;

  @media (max-width: 768px) {
    max-width: 85%;
  }

  @media (max-width: 480px) {
    margin: $base-margin;
  }

  h4 {
    margin: 0;
    width: 100%;
    padding: 20px;
    max-width: 800px;
  }

  label {
    color: $primary-color;
  }

  .subscriptionButton {
    border: none;
    border-radius: $base-radius;
    background-color: $third-background;
    padding: $small-padding-button;
    color: $primary-color;
    transition: 200ms all ease-in-out;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    position: relative;
  }

  .subscriptionButton:hover {
    background-color: $secondary-background;
    color: $third-background;
    box-shadow: none;
    border: 1px solid $third-background;
  }

  .response {
    margin-top: 20px;
  }
}

.JoinSupportSection.lightMode {
  h4 {
    background: linear-gradient(90deg, #494949 10%, $third-background 38.2%, $third-background 70.2%, #494949 88%);;
    z-index: 99;
  }
}

.JoinSupportSection.darkMode {
  h4 {
    background: linear-gradient(90deg, transparent 10%, $third-background 38.2%, $third-background 70.2%, transparent 88%);
  }
}
