* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-y: scroll;
  overflow-x: hidden;
  text-align: center;
  font-family: $third-font;
  width: 100vw;
}

h1 {
  padding: $large-margin;
  font-size: $large-font-size;
  line-height: $large-line-height;
  font-family: $secondary-font;

  @media (max-width: 786px) {
    font-size: $base-font-size;
    line-height: $base-line-height;
    padding: $mobile-margin;
  }
}

h2 {
  font-size: $base-font-size;
  line-height: $large-line-height;
  margin: $base-margin auto;
  letter-spacing: 1px;
  opacity: 80%;
  width: 40%;

  @media (max-width: 1200px) {
    width: 80%;
  }

  @media (max-width: 786px) {
    font-size: $small-font-size;
    line-height: $small-line-height;
  }
}

h3 {
  font-size: $large-font-size;
  line-height: $large-line-height;
  font-family: $primary-font;
  margin-bottom: $base-margin;

  @media (max-width: 786px) {
    font-size: $medium-font-size;
    line-height: $base-line-height;
  }

  @media (max-width: 480px) {
    font-size: $base-font-size;
  }
}

h4 {
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: 500;
  margin: $base-margin auto;
  width: 60%;
  letter-spacing: 1px;

  @media (max-width: 1200px) {
    width: 80%;
  }

  @media (max-width: 786px) {
    font-size: $small-font-size;
    line-height: $small-line-height;
  }

  @media (max-width: 480px) {
    font-size: $mobile-font-size;
    line-height: $small-line-height;
    width: 100%;
  }
}

p {
  font-size: $base-font-size;
  line-height: $base-line-height;
  width: 40%;
  margin: 0 auto;
  margin-bottom: $base-margin;
  letter-spacing: 1px;
  text-align: justify;

  @media (max-width: 1200px) {
    width: 60%;
  }

  @media (max-width: 786px) {
    font-size: $small-font-size;
    line-height: $base-line-height;
  }

  @media (max-width: 480px) {
    font-size: $mobile-font-size;
    line-height: $base-line-height;
    width: 80%;
  }
}

//footer

.DonateFooter button {
  background-color: $third-background;
  color: $primary-color;
}

//home

.Main.lightMode button, .CurrentExhibition button, .PastExhibition button {
  background-color: $third-background;
  color: $primary-color;
}

.Main.darkMode button {
  background-color: $primary-color;
  color: $third-background;
}

.Main.lightMode {
  background-color: $primary-color;
}

.Main.darkMode {
  background-color: rgba(0, 0, 0, 0.9);
  color: $primary-color;
}

//Pages

.AboutSection.lightMode, .ArtSection.lightMode, .ExhibitionSection.lightMode, 
.ArtistSection.lightMode, .ContactSection.lightMode {
  background-color: $primary-color;
}

.AboutSection.darkMode, .ArtSection.darkMode, .ExhibitionSection.darkMode, 
.ArtistSection.darkMode, .ContactSection.darkMode {
  background-color: rgba(0, 0, 0, 0.9);
  color: $primary-color;
}

form {
  padding: $base-padding;
  @include display(column, center);
  margin: 0 auto;
}

//button

button {
  font-size: $small-font-size;
  padding: $small-padding-button;
  margin-bottom: $small-margin;
  border: 1px solid transparent;
  border-radius: $base-radius;
  transition: 200ms all ease-in-out;
  box-shadow: $base-box-shadow;
  position: relative;
  margin-bottom: 10px;
  letter-spacing: 1px;
  background-color: $third-background;
  color: $primary-color;
}

button:hover {
  background-color: $secondary-background;
  color: $third-background;
  box-shadow: none;
  border: 1px solid $third-background;
}

input {
  padding: $inbox-padding;
  font-size: $base-font-size;
  line-height: $base-line-height;
  border-radius: $base-radius;
  border: $base-border;
  width: 100%;
  margin-bottom: $base-margin;

  @media (max-width: 786px) {
    font-size: $small-font-size;
    line-height: $small-line-height;
  }
}

label {
  font-size: $base-font-size;
  line-height: $base-line-height;
  color: $third-background;

  @media (max-width: 786px) {
    font-size: $small-font-size;
    line-height: $small-line-height;
  }
}

a {
  text-decoration: none;
  color: $primary-color;
}

img {
  border: $base-border;
}

span {
  color: $third-background;
}

video {
  width: 100%;
}

.mainImage {
  width: 400px;
  height: 480px;
}

.Completed,
.Cancel,
.VolunteersSection,
.JobsSection {
  margin: $large-margin;

  h4 {
    @media (max-width: 480px) {
      width: 90%;
    }
  }
}

.mode {
  @include display(row, center);
  span {
    margin-right: 10px;
    font-size: $base-font-size;
  }
}

.mode.lightMode {
  span {
    color: $secondary-color;
  }
}

.mode.lightMode:hover {
  span {
    color: $third-background;
  }
}

.mode.darkMode {
  span {
    color: $third-background;
  }
}


.upIcon {
  font-size: 30px;
  color: $third-background;
  transition: all 200ms ease;
  cursor: pointer;
}

.upIcon:hover {
  font-size: 30px;
  color: $primary-background;
}

