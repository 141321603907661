.ContactSection {
  h1 {
    padding-bottom: 20px;
  }
}

.ContactForm {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 300px;
    border-top-left-radius: 50% 300px;
    border-top-right-radius: 50% 300px;
    background-color: $secondary-background;
    z-index: -1;
  }
}

.ContactForm div {
  @include display(column, center);
}

.ContactForm p {
  text-align: center;
}

.ContactForm .inputContainer {
  height: 80px;
}

.ContactForm .textContainer {
  height: 400px;

  label {
    position: relative;
    bottom: 40px;
  }
}

.feather1, .feather2, .feather3, .featherL {
  width: 60px;
  height: 60px;
  border: none;
  position: relative;
}

.feather1, .feather2 {
  bottom: 100px;
  left: 50%;
}

.feather3 {
  bottom: 305px;
  left: 50%;
}

.featherL {
  bottom: 140px;
  right: 50%;
}

.ContactForm input {
  width: 400px;
  padding: $inbox-padding;
  position: relative;

  @media (max-width: 480px) {
    width: 270px;
  }
}
.ContactForm textarea {
  position: relative;
  bottom: 40px;
  width: 400px;
  height: 300px;
  border-radius: $base-radius;
  padding: $inbox-padding;
  border: $base-border;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-family: $primary-font;

  @media (max-width: 786px) {
    height: 230px;
    font-size: $small-font-size;
    font-size: $small-font-size;
    line-height: $small-line-height;
  }

  @media (max-width: 480px) {
    width: 270px;
    padding: $small-padding;
  }
}

.ContactForm .checkbox {
  @include display(row, center);
  width: 160px;
  position: relative;
  bottom: 170px;

  input {
    margin-top: 25px;
    width: 20px;
    margin-left: 30px;
  }

  label {
    width: 200px;
    font-weight: 600;

    @media (max-width: 480px) {
      width: 180px;
    }
  }
}

.ContactForm .response {
  text-align: center;
  position: relative;
  bottom: 160px;
}

.ContactForm .formButton {
  width: 400px;
  border: none;
  background-color: $third-background;
  color: $primary-color;
  border-radius: $base-radius;
  transition: 200ms all ease-in-out;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
  position: relative;
  padding: $small-padding-button;
  bottom: 180px;

  @media (max-width: 786px) {
    font-size: $mobile-font-size;
  }

  @media (max-width: 480px) {
    width: 270px;
    font-size: $small-mobile-font-size;
  }
}

.ContactForm .formButton:hover {
  background-color: $secondary-background;
  color: $third-background;
  box-shadow: none;
  border: 1px solid $third-background;
}
