.DonateFooter {
    @include display(column, center);
    color: $primary-color;
}

.DonateFooter.lightMode {
    background-color: rgba($primary-background, 0.9);
}

.DonateFooter.darkMode {
    background-color: $secondary-background;
}

.DonateFooter img {
    margin-bottom: $small-margin;
    width: 80px;
    height: 80px;
}

.DonateFooter button {
    max-width: 150px;
    height: 35px;
    background-color: $third-background;
}

.Main .DonateFooter button {
    background-color: $third-background;
}

.DonateFooter button:hover {
    background-color: $secondary-background;
    color: $third-background;
    box-shadow: none;
    border: 1px solid $third-background;
}



