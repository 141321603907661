.ArtSection {
  overflow-x: hidden;

  @media (max-width: 480px) {
    width: 100vw;
  }

  button {
    background-color: $third-background;
    color: $primary-color;
  }

  h2 {
    margin-bottom: 0;
    margin-top: 80px;
    padding: 0;

    @media (max-width: 786px) {
      width: 80%; 
    }
  }

  .artCollectionContainer {
    text-align: center;

    @media (max-width: 786px) {
      h2 {
        text-align: center;
        margin-bottom: 20px;
      }

      p {
        width: 80%;
      }

    }
  }

  .clickAway {
    padding: $mobile-padding;
    font-size: calc($large-font-size / 3 * 2);
    line-height: $large-line-height;
    width: 100vw;
    color: $third-background;
    margin-top: 0;
  
    @media (max-width: 786px) {
      font-size: $base-font-size;
      line-height: $large-line-height;
    }
  
    @media (max-width: 480px) {
      font-size: $small-font-size;
      line-height: $base-line-height;
    }
  }

  .topBox{
    width: 100vw;
    color: $primary-color;
    border-top: $large-border;
    border-bottom: $large-border;

    @media (max-width: 786px) {
      border-bottom: $mobile-border;
      border-top: $mobile-border;
    }

    h1 {
      line-height: $medium-line-height;
      padding: 20px 20%;
      margin-top: 20px;
      margin-bottom: 20px;

      @media (max-width: 786px) {
        padding: 10px 20%;
      }
    }

    .searchBox {
      width: 60%;
      height: 50px;
      @include display(row, center);
      text-align: center;
      margin: 0 auto;
      padding: 0;
      border: $mobile-border;
      background-color: $third-background;
      border-radius: 15px;
  
      @media (max-width: 786px) {
        width: 80%;
        border-radius: 10px;
        height: 40px;
      }
  
      .searchIcon {
        flex: 1;
        color: $primary-color;
        font-size: $large-font-size;
  
        @media (max-width: 786px) {
          flex: 2;
          font-size: $medium-font-size;
        }
      }
  
      .searchInput {
        flex: 9;
        height: 80%;
        position: relative;
        top: 14px;
        margin-right: 20px;
      }
    }

    .intro {
      margin: 10px;
      line-height: $medium-line-height;
      padding: 20px 20%;
      width: 100%;
      margin-bottom: 20px;
  
      @media (max-width: 786px) {
        padding: $small-padding;
        font-weight: 400;
        line-height: $base-line-height;
        width: 90%;
      }
    }
  }

  .freeDelivery {
    @media (max-width: 786px) {
      width: 100vw;
      margin-top: 100px;
    }
  }

  h4 {
    width: 100vw;
    background-color: $secondary-background;
    padding: 20px 100px;
    color: $primary-color;
    margin-bottom: 0;

    @media (max-width: 786px) {
      padding: 15px 30px;
    }
  }

  .searchPainting {
    h2 {
      color: $primary-color;
      margin-bottom: 40px;

      @media (max-width: 786px) {
        margin-top: 40px;
      }
    }
  }
}

.ArtSection.lightMode .topBox {
  background-color: rgba($primary-background, 0.9);
}

.ArtSection.darkMode .topBox {
  background-color: $secondary-background;
}

.ArtSection.lightMode .ArtGallery {
  background-color: rgba($primary-background, 0.9);

  h2 span {
    color: $primary-color;
  }

  h4 {
    background-color: transparent;
    font-weight: bold;
    color: $primary-color;
  }

  .buy span {
    color: $primary-color;
  }
}

.ArtSection.darkMode .ArtGallery {
  background-color: $secondary-background;

  h4 {
    color: $third-background;
  }
}

.ArtSection.lightMode h4 {
  background-color: rgba($primary-background, 0.9);
}

#artTop {
  scroll-margin-top: 8rem;
}

.freeDelivery {
  margin-top: 94px;
  background-color: $third-background;
  padding: $small-padding;
  font-size: calc($large-font-size / 3 * 2);
  line-height: $large-line-height;
  width: 100vw;
  color: $primary-color;

  @media (max-width: 786px) {
    font-size: $base-font-size;
    line-height: $large-line-height;
    margin-top: 80px;
  }

  @media (max-width: 480px) {
    font-size: $small-font-size;
    line-height: $base-line-height;
  }
}

button {
  width: 100px;

  @media (max-width: 786px) {
    width: 300px;
    height: 40px;
  }
}

