@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&family=Archivo+Black&display=swap&family=Poppins:wght@400;700&family=Raleway&display=swap");

$primary-font: "Poppins", sans-serif;
$secondary-font: "Archivo Black", sans-serif;
$third-font: "Raleway", sans-serif;

$small-mobile-font-size: 9px;
$mobile-font-size: 11px;
$small-font-size: 12px;
$base-font-size: 15px;
$medium-font-size: 19px;
$large-font-size: 22px;
$xlarge-font-size: 60px;

$mobile-line-height: 14px;
$small-line-height: 18px;
$base-line-height: 23px;
$medium-line-height: 30px;
$large-line-height: 35px;
$xlarge-line-height: 70px;

$primary-background: #353535;
$secondary-background: #2c272d;
$third-background: rgb(185, 137, 35);

$primary-color: white;
$secondary-color: #29272d;

// padding
$base-padding-button: 10px 30px;
$small-padding-button: 8px 18px;
$inbox-padding: 8px 18px;

$mobile-padding: 8px;
$small-padding: 15px;
$base-padding: 30px;
$medium-padding: 40px 15px;
$large-padding: 50px 20px;

//margin
$small-margin: 15px;
$base-margin: 30px;
$medium-margin: 80px 0 60px 0;
$mobile-margin: 130px 0 30px 0;
$base-main-margin: 100px 0 30px 0;
$large-margin: 140px 0 60px 0;
$bottom-margin: 50px;

//radius
$base-radius: 15px;
$large-radius: 50%;

//border
$base-border: 1px solid $third-background;
$small-border: 2px solid $third-background;
$mobile-border: 3px solid $third-background;
$large-border: 2px solid $third-background;

//shadow
$base-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
