@import 'variables.scss';
@import 'mixins.scss';

.TermsSection { 
    @include display(column, center);
    background-color: $primary-color;
    width: 80vw;
    padding: 100px;
    line-height: $base-line-height;
    font-size: $base-font-size;
    margin: auto;

    @media (max-width: 786px) {
        margin: 20px 0;
        padding: 90px 0 0;
        width: 100vw;
    }

    @media (max-width: 480px) {
        font-size: $mobile-font-size;
        line-height: $mobile-line-height;
    }
}

.TermsSection h2 {
    margin: 40px 0;

    @media (max-width: 786px) {
        margin: 20px 0;
    }
}

.TermsSection ul {
    list-style: none;
    text-align: center;
    margin: 20px 0;
    width: 60vw;
    font-size: $base-font-size;
    line-height: $large-line-height;
    letter-spacing: 1px;


    @media (max-width: 1200px) {
        font-size: $base-font-size;
        line-height: $base-line-height;
        width: 80vw;
    }

    @media (max-width: 786px) {
        margin: 10px 0;
    }

    @media (max-width: 480px) {
        font-size: $mobile-font-size;
        line-height: $mobile-line-height;
    }
}

.TermsSection ul li {
    margin-bottom: 20px;
}

.TermsSection ul li a {
    color: $secondary-background;
    cursor: pointer;
    text-decoration: underline;
}

.TermsSection ul li a:hover {
    color: $secondary-color;
}

.TermsSection ul .title {
    font-weight: 600;
    margin-bottom: 10px;
    opacity: 80%;
}