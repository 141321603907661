.Section {
    @include display(row, center);
    margin: auto;
    width: 80%;   
    padding: $base-padding; 
    border-bottom: $large-border;

    .mainImage {
        width: 240px;
        height: 300px;
    }

    h3 {
        color: $third-background;
    }
    
    @media (max-width: 786px) {
        @include display(column, center);
        width: 100%; 
        border-bottom: $small-border;
        border-top: $small-border;
      
        .mainImage {
            margin-top: $base-margin;
            width: 120px;
            height: 150px;
        }
    }

    @media (max-width: 480px) {
        width: 100%;  
        border-bottom: $mobile-border;
        border-top: none; 
    }

    a {
        .sectionButton {
            border: 1px solid transparent;
        }

        .sectionButton:hover {
            border: 1px solid $third-background;
            background-color: $secondary-background;
        }
    }
}

.Section.lightMode {
    a {
        .sectionButton:hover {
            background-color: $secondary-background;
            border: 1px solid $third-background;
        }
    }
}

.Section1 {
    color: $third-background;

    h4 {
        color: $primary-color;
    }
}

.Section1.lightMode {
    background-color: rgba($primary-background, 0.9);

    h2 {
        color: $primary-color;
        border-bottom: 1px solid $primary-color;
        width: 122px;
    }
}

.Section1.darkMode {
    background-color: $secondary-background;
}

