.ArtGallery {
  @include display(column, center);
  margin: 0 auto;
  width: 60%;
  border: $large-border;
  margin-bottom: $base-margin;
  position: relative;
  box-shadow: $base-box-shadow;
  color: $primary-color;
  overflow-x: hidden;
  
  @media (max-width: 1200px) {
    width: 80%;
    border: $mobile-border;
  }

  @media (max-width: 786px) {
    width: 100vw;
    padding: 40px;
    gap: 40px;
    height: 100%;
    border-left: none;
    border-right: none;
  }

  @media (max-width: 480px) {
    width: 100vw;
    border-top: none;
    border-bottom: $mobile-border;

  }

  .buttons {
    border: 2px solid rgba(0, 0, 0, 0.6);
    background-color: rgba(250, 250, 250, 0.1);
    padding: 30px 20px 20px 20px;
    width: 98%;
    font-size: $mobile-font-size;
    border-radius: 15px;
    text-align: center;
    @include display(row, center);
    gap: 10px;

    @media (max-width: 760px) {
      background-color: rgba(0, 0, 0, 0.2);
      @include display(column, center);
    }
  }

  .buy {
    font-size: $medium-font-size;
    margin-bottom: 10px;
    font-weight: bold;

    @media (max-width: 769px) {
      font-size: $base-font-size;
    }

    @media (max-width: 480px) {
      font-size: $mobile-font-size;
    }
  }

  a {
    button {
      @media (max-width: 786px) {
        font-size: $small-mobile-font-size;
        padding: $small-padding-button;
      }
    }

    button:disabled {
      background-color: grey;
      box-shadow: none;
    }
  
    .button:disabled:hover {
      background-color: grey;
      border: 1px solid transparent;
    }

    .card:hover {
      background-color: $secondary-background;
      border: 1px solid $third-background;
    }

    .payPal {
      margin-left: 10px;
      background-color: rgb(34, 34, 139);

      @media (max-width: 786px) {
        margin-left: 0;
      }
    }
  }

  .basketButton {
    @include display(row, center);
    gap: 5px;
    height: 34px;
    margin-left: 10px;
    
    .basket {
      font-size: $large-font-size;
    }

    @media (max-width: 786px) {
      height: 40px;
      margin-left: 0;

      .basket {
        font-size: $base-font-size;
      }
    }
  }

  .basketButton:hover {
    background-color: $secondary-background;
    
    .basket {
      font-size: $large-font-size;
    }
  }

  .basketButton:disabled {
    background-color: grey;
    box-shadow: none;
  }

  .basketButton:disabled:hover {
    background-color: grey;
    border: 1px solid transparent;
  }

  .paintingDescription {
    @include display(row, space-between);
    height: 400px;
    width: 95%;

    @media (max-width: 786px) {
      height: 100%;
      @include display(column, space-between);
    }

    @media (max-width: 480px) {
      width: 100vw;
    }

    .paintingImage {
      flex: 1;
      margin: auto;
      width: 100%;

      div {
        img {
          max-width: 90%;
          max-height: 300px;
          box-shadow: $base-box-shadow;
          align-items: center;
        }
      }
    }

    .paintingDescriptionContainer {
      flex: 1;
      margin: auto;
      width: 100%;
      @include display(column, center);
      gap: 20px;
  
      a {
        @include display(column, center);
        gap: 10px;

        h2 {
          width: 100%;
          margin: 0;
          text-shadow: $base-box-shadow;
          letter-spacing: 3px;
          font-size: $medium-font-size;
          line-height: $medium-line-height;
          padding-bottom: 20px;
      
          @media (max-width: 786px) {
            letter-spacing: 2px;
            font-size: $base-font-size;
            line-height: $base-line-height;
            padding-bottom: 15px;
            margin-top: 40px;
          }
        
          @media (max-width: 480px) {
            margin-bottom: 5px;
            padding-bottom: 5px;
            font-size: $mobile-font-size;
          }
        }
        
         h3 {
          font-size: $small-font-size;
          line-height: $base-line-height;
          margin: 0;
          font-weight: 400;
          opacity: 0.85;
      
          @media (max-width: 786px) {
            font-size: $mobile-font-size;
          }
        
          @media (max-width: 480px) {
            font-size: $small-mobile-font-size;
          }
        }
        
         h4 {
          margin: 0 auto;
          padding: 10px 0 20px 0;
          font-weight: 600;
          width: 100%;
        
          @media (max-width: 480px) {
            padding: $small-padding;
            font-size: $small-font-size;
          }
        }
      }
      .soldButton {
        background-color: #2c272d;
        box-shadow: none;
      }
    
      .soldButton:hover {
        background-color: #2c272d;
        transform: translateY(0);
        box-shadow: none;
      }
      }
    }
  }

.shadowPoem {
  line-height: $large-line-height;
  margin-bottom: $base-margin;
  font-weight: 600;
  color: $third-background;

  @media (max-width: 480px) {
    line-height: $base-line-height;
    font-size: $mobile-font-size;
    width: 80%;
    margin: auto;
    margin-bottom: $base-margin;
  }
}

