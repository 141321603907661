@import 'variables.scss';
@import 'mixins.scss';

.ReturnSection { 
    @include display(column, center);
    background-color: $primary-color;
    max-width: 1000px;
    padding: 100px;
    line-height: $base-line-height;
    font-size: $base-font-size;
    margin: auto;

    @media (max-width: 786px) {
        margin: 20px 0;
        padding: 90px 0 0;
        width: 100vw;
    }
}

.ReturnSection h2 {
    margin: 40px 0 0 0;

    @media (max-width: 786px) {
        margin: 20px 0 10px 0;
    }

    @media (max-width: 480px) {
        margin: 15px 0 5px 0;
    }
}

.ReturnSection h3 {
    margin: 40px 0 20px 0;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 400;
    text-align: left;
    
    @media (max-width: 786px) {
        margin: 20px;
        font-size: $small-font-size;
        line-height: $small-line-height;
    }

    @media (max-width: 480px) {
        margin: 20px;
        font-size: $mobile-font-size;
        line-height: $mobile-line-height;
    }
}

.ReturnSection ul {
    list-style: none;
    text-align: left;
    margin: 20px 0;
    max-width: 1000px;
    font-size: $base-font-size;
    line-height: $base-line-height;
    letter-spacing: 1px;
    list-style: circle;


    @media (max-width: 1200px) {
        font-size: $small-font-size;
        width: 80vw;
    }

    @media (max-width: 786px) {
        margin: 10px 0;
        font-size: $small-font-size;
        line-height: $small-line-height;
    }

    @media (max-width: 480px) {
        font-size: $mobile-font-size;
        line-height: $mobile-line-height;
    }
}

.ReturnSection ul .title {
    font-weight: 600;
    margin-bottom: 10px;
    opacity: 80%;
    margin-bottom: 20px;
    list-style: none;
}

.ReturnForm {
    width: 100%;
    height: 100vh;
    position: relative;
    margin: 0 auto;
    padding-top: 40px;;

    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 400px;
        border-top-left-radius: 50% 300px;
        border-top-right-radius: 50% 300px;
        background-color: $secondary-background;
        z-index: -1;
    }

    .close {
        color: $third-background;
        position: fixed;
        right: 20px;
        top: 10px;
        font-weight: bold;
    }

    .close:hover {
        color: $secondary-background;
    }

    input {
        width: 400px;
        padding: $inbox-padding; 

        @media (max-width: 480px) {
            width: 270px;;
        }
    }

    textarea {
        width: 400px;
        height: 300px;
        border-radius: $base-radius;
        padding: $inbox-padding;
        border: $base-border;
        font-size: $base-font-size;
        line-height: $base-line-height;
        font-family: $primary-font;

        @media (max-width: 786px) {
            height: 230px;
            font-size: $small-font-size;
            font-size: $small-font-size;
            line-height: $small-line-height;
        }
    
        @media (max-width: 480px) {
        width: 270px;
        padding: $small-padding;
        }
    }

    .checkbox {
        @include display(row, center);
        width: 180px;
      
        input {
          margin-top: 25px;
          width: 20px;
          margin-left: 30px;

          @media (max-width: 786px) {
            margin-left: 20px;
        }
        }
      
        label {
          width: 200px;
          font-weight: 600;
      
          @media (max-width: 480px) {
            width: 180px;
          }
        }
      }

      .response {
        text-align: center;
      }

    .formButton {
        width: 400px;
        border: none;
        background-color: $third-background;
        color: $primary-color;
        border-radius: $base-radius;
        transition: 200ms all ease-in-out;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
        padding: $small-padding-button;

        @media (max-width: 786px) {
            font-size: $mobile-font-size;
        }
    
        @media (max-width: 480px) {
            width: 270px;
            font-size: $small-mobile-font-size;
        }
    }

    .formButton:hover {
        background-color: $secondary-background;
        color: $third-background;
        box-shadow: none;
        border: 1px solid $third-background;
    }
      
}