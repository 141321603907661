.Image {
    background-color: $secondary-background;
    height: 100vh;
    width: 100vw;
    padding: 10px;
    @include display(column, center);
    gap: 30px;

    div {
      transition: 200ms all ease-in-out;
      width: 500px;
      max-width: 80%;
      height: auto;
  
      @media (max-width: 786px) {
        width: 100%;
      }

      .mainImage {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

  .mainImage:hover {
    border: none;
  }

  a {
    padding: 50px;
    width: 10%;

    @media (max-width: 786px) {
      width: 4%;
    }

    .close {
    color: $third-background;
    font-size: 25px;
    font-weight: bold;
    z-index: 99;
    cursor: pointer;

    @media (max-width: 786px) {
      left: 92%;
      font-size: 25px;
      bottom: 220px;
      right: 50px;
    }

    @media (max-width: 480px) {
      left: 91%;
      font-size: 20px;
    }
    }

    .close:hover {
      color: $primary-color;
    }  
  }

  .empty {
    flex: 1;
    width: 10%;
    padding: 50px;

    @media (max-width: 786px) {
      width: 4%;
    }
  }
}