.ReviewSection {
  margin: $large-margin;

  p {
    @media (max-width: 786px) {
      width: 80%;
    }
  }

  input {
    width: 400px;

    @media (max-width: 480px) {
      width: 270px;
    }
  }
  textarea {
    width: 400px;
    height: 400px;
    border-radius: $base-radius;
    padding: $base-padding;
    border: $base-border;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-family: $primary-font;

    @media (max-width: 786px) {
      height: 250px;
      font-size: $small-font-size;
    }

    @media (max-width: 480px) {
      width: 270px;
      padding: $small-padding;
    }
  }

  .checkbox {
    @include display(row, center);
    width: 400px;

    input {
      margin-top: 25px;
      width: 20px;
      height: 20px;
      margin-left: 40px;
    }

    label {
      width: 200px;
      font-weight: 600;

      @media (max-width: 480px) {
        width: 180px;
      }
    }
  }
}
