.TopBanner {
    position: relative;
    z-index: -1;
    @include display(column, center);
    width: 100vw;
    height: 100vh;

    @media (max-width: 768px) {
        height: 60vh; 
    }
}

.TopBanner.lightMode {
    background: rgba($third-background, 0.9);
}

.TopBanner.darkMode {
    background: $secondary-color;
}

.TopBanner .titleContainer {
    position: absolute;
    @include display(column, center);
    width: 100%;
    padding: $base-padding;
}

.TopBanner.lightMode .titleContainer span {
    color: $secondary-background;
}

.TopBanner.darkMode .titleContainer {
    color: $third-background;
}

.TopBanner h1 {
    font-size: 90px;
    line-height: 95px;
    font-family: $secondary-font;
    margin: 0;
    padding: 0;
    color: $primary-color;
    z-index: 99;

    span{
        font-size: 25px;
        line-height: 30px;
        letter-spacing: 3px;
    
        @media (max-width: 1200px) {
            font-size: 23px;
            line-height: 30px;
        }
    
        @media (max-width: 1000px) {
            font-size: 18px;
            line-height: 25px;
        }
    
        @media (max-width: 768px) {
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 2px;
            font-weight: 500;
        }
    
        @media (max-width: 480px) {
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 1px;
            font-weight: 400;
        }
    }

    @media (max-width: 1200px) {
        font-size: 80px;
        line-height: 90px;
    }

    @media (max-width: 1000px) {
        font-size: 60px;
        line-height: 70px;
    }

    @media (max-width: 768px) {
        font-size: 40px;
        line-height: 50px;
    }

    @media (max-width: 480px) {
        font-size: 30px;
        line-height: 40px;
    }
}
